<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <suspensao
      :activator="false"
      :permission="permission"
      model="Pedido"
      :item="pedidoSuspensao"
      ref="suspensao"
    >
    </suspensao>
    <div class="mt-4">
      <v-card-text>
        <v-tabs icons-and-text v-model="tab">
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#ativos" @click="handleGetPedidos(true)">Ativos</v-tab>
          <v-tab href="#inativos" @click="handleGetPedidos(false)"
            >Inativos</v-tab
          >

          <v-tabs-items v-model="tab">
            <v-tab-item value="ativos">
              <v-card-title>
                Filtro
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Pesquisa"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="items"
                :server-items-length="pedidos.count"
                class="border"
                :options.sync="options"
                @update:options="handleGetPedidos(true)"
                :item-class="suspendido"
              >
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    <v-toolbar-title>Pedidos registrados</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-icon
                      v-if="canList"
                      medium
                      @click="handleGetPedidos(true)"
                      title="Atualizar"
                      >mdi-refresh</v-icon
                    >
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-switch
                      v-model="showPesagens"
                      label="Mostrar pesagens"
                      class="pa-3 mt-5"
                    ></v-switch>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog
                      v-model="dialog"
                      max-width="1000px"
                      v-if="podeAdicionar"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-if="canCreate"
                          color="primary"
                          dark
                          class="mb-2"
                          v-on="on"
                          @click="adicionarPedido"
                          >Novo</v-btn
                        >
                      </template>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.data_inicio`]="{ item }">
                  {{ item.data_inicio | date2br }}
                </template>
                <template v-slot:[`item.data_fim`]="{ item }">
                  {{ item.data_fim | date2br }}
                </template>
                <template
                  v-slot:[`item.quantidade_restante_agendamento`]="{ item }"
                >
                  {{ item | quantidade_restante_agendamento }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        class="mr-2 mb-2"
                        dark
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <div>
                        <v-list-item
                          class="pointer"
                          v-if="
                            !item.suspendido &&
                            valido &&
                            !inativo(item) &&
                            (!item.pedido_pai ||
                              (item.pedido_pai && !item.pedido_pai.suspendido))
                          "
                          @click="handleAgendar(item)"
                          >Agendar</v-list-item
                        >
                        <v-list-item class="pointer" @click="exportarCsv(item)"
                          >Exportar csv</v-list-item
                        >
                        <v-list-item
                          class="pointer"
                          @click="detalhesDistribuicao(item)"
                          >Detalhes</v-list-item
                        >
                        <v-list-item
                          v-if="canUpdate && podeEditar && !item.transportadora"
                          class="pointer"
                          @click="() => handleEditar(item)"
                          >Editar</v-list-item
                        >
                        <v-list-item
                          v-if="podeDistribuir && canDistribuirTransportadora"
                          class="pointer"
                          @click="
                            () => handleDistribuirParaTransportadora(item)
                          "
                          >Distribuir p/ transportadora</v-list-item
                        >
                        <v-list-item
                          v-if="canSuspender"
                          class="pointer"
                          @click="() => suspender(item)"
                        >
                          {{
                            item.suspendido ||
                            (item.pedido_pai && item.pedido_pai.suspendido)
                              ? 'Suspensão'
                              : 'Suspender'
                          }}
                        </v-list-item>
                      </div>
                    </v-list>
                  </v-menu>
                </template>

                <template v-slot:no-data>
                  <v-btn
                    v-if="canList"
                    color="primary"
                    @click="handleGetPedidos(true)"
                    >Reset</v-btn
                  >
                  <div v-if="!canList">
                    <span class="red--text"
                      >Você não tem permissão para visualizar as informações
                      desta tela</span
                    >
                  </div>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item value="inativos">
              <v-card-title>
                Filtro
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Pesquisa"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="items"
                :server-items-length="pedidos.count"
                class="border"
                :options.sync="options"
                @update:options="handleGetPedidos(false)"
                :item-class="suspendido"
              >
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    <v-toolbar-title>Pedidos registrados</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-icon
                      v-if="canList"
                      medium
                      @click="handleGetPedidos(false)"
                      title="Atualizar"
                      >mdi-refresh</v-icon
                    >
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-switch
                      v-model="showPesagens"
                      label="Mostrar pesagens"
                      class="pa-3 mt-5"
                    ></v-switch>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog
                      v-model="dialog"
                      max-width="1000px"
                      v-if="podeAdicionar"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-if="canCreate"
                          color="primary"
                          dark
                          class="mb-2"
                          v-on="on"
                          @click="adicionarPedido"
                          >Novo</v-btn
                        >
                      </template>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.data_inicio`]="{ item }">
                  {{ item.data_inicio | date2br }}
                </template>
                <template v-slot:[`item.data_fim`]="{ item }">
                  {{ item.data_fim | date2br }}
                </template>
                <template
                  v-slot:[`item.quantidade_restante_agendamento`]="{ item }"
                >
                  {{ item | quantidade_restante_agendamento }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        class="mr-2 mb-2"
                        dark
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <div>
                        <v-list-item
                          class="pointer"
                          v-if="
                            !item.suspendido &&
                            valido &&
                            !inativo(item) &&
                            (!item.pedido_pai ||
                              (item.pedido_pai && !item.pedido_pai.suspendido))
                          "
                          @click="handleAgendar(item)"
                          >Agendar</v-list-item
                        >
                        <v-list-item class="pointer" @click="exportarCsv(item)"
                          >Exportar csv</v-list-item
                        >
                        <v-list-item
                          class="pointer"
                          @click="detalhesDistribuicao(item)"
                          >Detalhes</v-list-item
                        >
                        <v-list-item
                          v-if="canUpdate && podeEditar && !item.transportadora"
                          class="pointer"
                          @click="() => handleEditar(item)"
                          >Editar</v-list-item
                        >
                        <v-list-item
                          v-if="podeDistribuir && canDistribuirTransportadora"
                          class="pointer"
                          @click="
                            () => handleDistribuirParaTransportadora(item)
                          "
                          >Distribuir p/ transportadora</v-list-item
                        >
                        <v-list-item
                          v-if="canSuspender"
                          class="pointer"
                          @click="() => suspender(item)"
                        >
                          {{
                            item.suspendido ||
                            (item.pedido_pai && item.pedido_pai.suspendido)
                              ? 'Suspensão'
                              : 'Suspender'
                          }}
                        </v-list-item>
                      </div>
                    </v-list>
                  </v-menu>
                </template>

                <template v-slot:no-data>
                  <v-btn
                    v-if="canList"
                    color="primary"
                    @click="handleGetPedidos(false)"
                    >Reset</v-btn
                  >
                  <div v-if="!canList">
                    <span class="red--text"
                      >Você não tem permissão para visualizar as informações
                      desta tela</span
                    >
                  </div>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
        <download-excel
          v-if="canCreate"
          name="pedidos.xls"
          :data="items"
          :fields="fields_excel"
        >
          <v-btn color="info" class="w-100 mt-5"> Gerar Excel </v-btn>
        </download-excel>
      </v-card-text>
    </div>
    <v-overlay :value="carregaDetalhes">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <v-dialog v-model="detalhar">
      <detalhes-programacao
        :detalhes="detalhes"
        :title="title"
        :headers="
          headersDetalhes.filter((i) => (!showPesagens ? !i.pesagem : true))
        "
        :totalPorTransportadora="totalPorTransportadora"
        :headersTotalTransportadora="headersTotalTransportadora"
        :download="download"
        @close="() => (detalhar = false)"
      />
    </v-dialog>
    <v-dialog v-model="dialogDeleteItem" max-width="500px">
      <v-card>
        <v-card-title>
          <v-card-text>
            <h3 class="transition-swing text-h3 mb--1 error--text">Cuidado!</h3>
            <p class="mt-8">Tem certeza que quer deletar esse pedido?</p>
          </v-card-text>
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text> Cancelar </v-btn>
          <v-btn color="primary" text @click="deleteItem(pedidoDelete)">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Perfil from '@/utils/perfil'
import TipoAgendamento from '@/utils/agendamento'
import {
  BasicPermissions,
  Sequences,
  CustomPermissions,
} from '@/utils/permissions'
import ExtracaoApi from '@/services/extracao/index'
import { mapActions, mapState } from 'vuex'
import Suspensao from '@/components/suspensao/suspensao.vue'
import DetalhesProgramacao from '@/components/patio/Programacao/Detalhes/DetalhesProgramacao'

import _ from 'lodash'
export default {
  name: 'ListarPedido',
  components: { Suspensao, DetalhesProgramacao },
  data() {
    return {
      tab: null,
      dialog: false,
      loading: false,
      valido: true,
      detalhes: [],
      totalPorTransportadora: [],
      title: {},
      download: {
        type: '',
        public_id: '',
      },
      detalhar: false,
      headersDetalhes: [
        {
          text: 'Veículo',
          value: 'veiculo',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Data da Cota',
          value: 'data_cota',
        },
        {
          text: 'Triado Em',
          value: 'triado_em',
        },
        {
          text: 'Liberado Em',
          value: 'saida_do_patio',
        },
        {
          text: 'Tempo em patio',
          value: 'tempo_em_patio',
        },
        {
          text: 'Finalizado Em',
          value: 'chegada_terminal',
        },
        {
          text: 'Cliente',
          value: 'cliente',
        },
        {
          text: 'Produto',
          value: 'produto',
        },
        {
          text: 'Sub Produto',
          value: 'sub_produto',
        },
        {
          text: 'Nota fiscal',
          value: 'num_nota_fiscal',
        },
        {
          text: 'Quantidade (KG)',
          value: 'quantidade',
        },
        {
          text: 'Peso Bruto (KG)',
          value: 'peso_bruto',
          pesagem: true,
        },
        {
          text: 'Peso TARA (KG)',
          value: 'peso_tara',
          pesagem: true,
        },
        {
          text: 'Peso Liquido (KG)',
          value: 'peso_liquido',
          pesagem: true,
        },
        {
          text: 'Quebra (Kg)',
          value: 'peso_quebra',
          pesagem: true,
        },
        {
          text: 'Quebra (%)',
          value: 'perc_quebra',
          pesagem: true,
        },

        {
          text: 'Embalagem',
          value: 'embalagem',
        },
        {
          text: 'Modalidade',
          value: 'modalidade',
        },
        {
          text: 'Eixos',
          value: 'eixos',
        },
      ],
      carregaDetalhes: false,
      options: {},
      search: '',
      fields_excel: {
        'Data Inicio': {
          callback: (value) => {
            let data = new Date(value.data_inicio).toLocaleString('pt-br')
            return `${data}`
          },
        },
        'Data Fim': {
          callback: (value) => {
            let data = new Date(value.data_fim).toLocaleString('pt-br')
            return `${data}`
          },
        },
        'Número Pedido Importação': 'numero_pedido_importacao',
        'Número Pedido': 'numero_pedido',
        Fornecedor: 'fornecedor.business_name',
        'Cidade do Fornecedor': 'fornecedor.cidade',
        'UF do Fornecedor': 'fornecedor.uf',
        Cliente: 'cliente.business_name',
        'Cidade do Cliente': 'cliente.cidade',
        'UF do Cliente': 'cliente.uf',
        'Tipo frete': 'tipoFrete.descricao',
        'Tipo Operação': 'tipo_operacao.descricao',
        Produto: 'produto.descricao',
        'Sub Produto': 'subProduto.descricao',
        Embalagem: 'embalagem.descricao',
        'Qtd. Pedido (TON)': 'quantidade',
        'Qtd. Distribuida (TON)': 'quantidade_distribuida',
        'Saldo a distribuir (TON)': 'quantidade_restante',
        'Qtd. Agendada (TON)': 'quantidade_agendada',
        'Qtd. Atendida (TON)': 'quantidade_atendida',
        'Saldo Pedido (TON)': 'quantidade_restante_agendamento',
        'Qtd. Cancelado (TON)': 'quantidade_cancelada',
        // Valor: {
        //   callback: (value) => {
        //       return `${this.numberToReal(value.valor)}`
        //   },
        // },
        // 'Forma de pagamento': 'forma_pagamento.descricao',
        // Placa: 'placa_cavalo',
        // Motorista: 'motorista',
        // Transportador: 'transportadora.business_name',
        // Terminal: 'terminal',
      },
      headers: [
        { text: 'Ações', value: 'actions', sortable: false },
        { text: 'Data Inicio', value: 'data_inicio' },
        { text: 'Data Fim', value: 'data_fim' },
        { text: 'Descricao', value: 'descricao' },
        { text: 'Número Pedido Importação', value: 'numero_pedido_importacao' },
        { text: 'Número Pedido', value: 'numero_pedido' },
        { text: 'Fornecedor', value: 'fornecedor.business_name' },
        { text: 'Cidade do Fornecedor', value: 'fornecedor.cidade' },
        { text: 'UF do Fornecedor', value: 'fornecedor.uf' },
        { text: 'Cliente', value: 'cliente.business_name' },
        { text: 'Cidade do Cliente', value: 'cliente.cidade' },
        { text: 'UF do Cliente', value: 'cliente.uf' },
        { text: 'Tipo frete', value: 'tipoFrete.descricao' },
        { text: 'Tipo Operação', value: 'tipo_operacao.descricao' },
        { text: 'Produto', value: 'produto.descricao' },
        { text: 'Sub Produto', value: 'subProduto.descricao' },
        { text: 'Embalagem', value: 'embalagem.descricao' },
        { text: 'Qtd. Pedido (TON)', value: 'quantidade' },
        { text: 'Qtd. Distribuida (TON)', value: 'quantidade_distribuida' },
        { text: 'Saldo a distribuir (TON)', value: 'quantidade_restante' },
        { text: 'Qtd. Agendada (TON)', value: 'quantidade_agendada' },
        { text: 'Qtd. Atendida (TON)', value: 'quantidade_atendida' },
        {
          text: 'Saldo Pedido (TON)',
          value: 'quantidade_restante_agendamento',
        },
        { text: 'Qtd. Cancelado (TON)', value: 'quantidade_cancelada' },
        { text: 'Navio', value: 'navio.descricao' },
      ],
      headersTotalTransportadora: [
        { text: 'Transportadora', value: 'transportadora_name' },
        { text: 'Peso', value: 'peso' },
        { text: 'Performace', value: 'performace' },
      ],
      editedIndex: -1,
      editedItem: {
        descricao: '',
      },
      defaultItem: {
        descricao: '',
      },
      dialogDeleteItem: false,
      pedidoDelete: {},
      pedidoSuspensao: {},
      showPesagens: false,
    }
  },
  computed: {
    ...mapState('patio', ['pedidos']),
    ...mapState('auth', ['empresaAtual', 'perfilAtual']),
    name() {
      const date = new Date()
      const data = `${`${date.getFullYear()}`.padStart(4, 0)}-${`${
        date.getMonth() + 1
      }`.padStart(2, 0)}-${`${date.getDate()}`.padStart(
        2,
        0
      )}T${`${date.getHours()}`.padStart(
        2,
        0
      )}:${`${date.getMinutes()}`.padStart(
        2,
        0
      )}:${`${date.getSeconds()}`.padStart(
        2,
        0
      )}.${`${date.getMilliseconds()}`.padStart(3, 0)}`
      return `exportacao_pedido${data}`
    },
    permission() {
      return Sequences.Pedidos.toString()
    },

    canAgendar() {
      return this.$canDo(CustomPermissions.AGENDAR, this.permission)
    },
    canExportarCSV() {
      return this.$canDo(CustomPermissions.EXPORTAR_CSV, this.permission)
    },
    canDistribuirTransportadora() {
      return this.$canDo(
        CustomPermissions.DISTRIBUIR_TRANSPORTADORA,
        this.permission
      )
    },
    canSuspender() {
      return this.$canDo(CustomPermissions.SUSPENDER, this.permission)
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },

    formTitle() {
      return this.editedIndex === -1 ? 'Novo' : 'Editar'
    },

    podeAdicionar() {
      return (
        this.empresaAtual.tiposContrato &&
        (this.empresaAtual.tiposContrato.includes(Perfil.Fornecedor) ||
          this.empresaAtual.gate)
      )
    },

    podeEditar() {
      return (
        this.empresaAtual.tiposContrato &&
        (this.empresaAtual.tiposContrato.includes(Perfil.Cliente) ||
          this.empresaAtual.tiposContrato.includes(Perfil.Unidade) ||
          this.empresaAtual.tiposContrato.includes(Perfil.Fornecedor))
      )
    },
    // teste
    podeDistribuir() {
      return (
        this.empresaAtual.tiposContrato &&
        this.empresaAtual.tiposContrato.includes(Perfil.Cliente)
      )
    },
    items() {
      if (this.search.length > 0) {
        return (
          this.pedidos.results.filter((item) => {
            return this.headers.some((prop) => {
              try {
                const value = _.get(item, prop.value)
                return String(value)
                  .toLocaleUpperCase()
                  .includes(this.search.toLocaleUpperCase())
              } catch (error) {
                return false
              }
            })
          }) || []
        )
      }
      return this.pedidos.results
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  async created() {
    this.Perfil = Perfil
    if (this.canList) {
      await this.handleGetPedidos(true)
    }
  },
  filters: {
    date2br(data) {
      if (!data) return '-'
      const data_splitted = data.split('-')
      return `${data_splitted[2]}/${data_splitted[1]}/${data_splitted[0]}`
    },
    quantidade_restante_agendamento(item) {
      let restante =
        item.quantidade - item.quantidade_agendada - item.quantidade_atendida
      return parseFloat((restante > 0 ? restante : 0).toFixed(2))
    },
  },
  methods: {
    ...mapActions('patio', [
      'getPedidos',
      'getPedido',
      'addPedido',
      'editPedido',
      'deletePedido',
    ]),
    inativo(item) {
      if (new Date(item.data_fim + 'T23:59:59') < new Date()) {
        return true
      }
    },
    async detalhesDistribuicao(item) {
      try {
        this.carregaDetalhes = true
        const data = await ExtracaoApi.getPedidoDetalhe({
          public_id: item.public_id,
        })

        const dataPorTransportadora = await ExtracaoApi.getPedidoDetalhePorTransportadora(
          {
            public_id: item.public_id,
          }
        )

        this.totalPorTransportadora = dataPorTransportadora.transportadoras

        this.detalhes = data.agendamentos
        this.title = data.title
        if (!this.showPesagens) {
          delete this.title.cotas_liquida
          delete this.title.cotas_liquida_performace
          delete this.title.cotas_atendidas_quebra
          delete this.title.cotas_atendidas_quebra_perc
        }
        this.download.type = 'Pedido'
        this.download.public_id = item.public_id
        this.detalhar = true
      } catch (error) {
        console.log(error)
        this.errorMessage(
          'Erro ao buscar detalhes. Tente novamente ou contacte o suporte.'
        )
      } finally {
        this.carregaDetalhes = false
      }
    },
    suspendido(item) {
      if (item.pedido_pai && item.pedido_pai.suspendido) {
        return 'red--text'
      }
      return item.suspendido ? 'red--text' : ''
    },
    suspender(item) {
      if (item.pedido_pai && item.pedido_pai.suspendido) {
        this.pedidoSuspensao = item.pedido_pai
      } else {
        this.pedidoSuspensao = item
      }
      this.$nextTick(() => {
        this.$refs['suspensao'].open()
      })
    },
    async handleGetPedidos(valido) {
      this.loading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      let ordering
      if (sortBy && sortBy.length === 1 && sortDesc && sortDesc.length === 1) {
        ordering = this.options.sortBy[0]
        ordering = this.options.sortDesc[0] ? '-' + ordering : ordering
        ordering = ordering.replaceAll('.', '__')
      }
      await this.getPedidos({
        proprietario: this.empresaAtual.public_id,
        pai: this.perfilAtual == Perfil.Transportadora ? false : true,
        valido: valido,
        page_size: itemsPerPage > 0 ? itemsPerPage : 1000,
        page: page,
        ordering: ordering,
        //   fornecedor: this.empresaAtual.public_id,
        //   cliente: this.empresaAtual.public_id,
        //   transportadora: this.empresaAtual.public_id
      })
      this.loading = false
    },

    adicionarPedido() {
      this.$emit('showForm')
    },

    async editItem(item) {
      const pedido = await this.getPedido(item.public_id)
      this.$emit('editItem', pedido)
    },

    prepareDeleteItem(item) {
      this.dialogDeleteItem = true
      this.pedidoDelete = item
    },

    async deleteItem(item) {
      if (item.id) {
        await this.deletePedido(item)
      } else {
        alert('Houve um problema. Tente novamente!')
        return false
      }
      this.dialogDeleteItem = false
      this.pedidoDelete = {}
    },

    close() {
      this.dialog = false
      setTimeout(() => {
        Object.assign(this.editedItem, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    save() {
      if (this.editedIndex > -1) {
        this.editPedido(this.editedItem) // REVER.. Editar
      } else {
        this.addPedido(this.editedItem)
      }
      this.close()
    },
    async exportarCsv(item) {
      try {
        console.log(item)
        const data = await ExtracaoApi.getExportCsvPedidos({
          public_id: item.public_id,
        })
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', this.name + '.csv') //or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        if (error.response) {
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      }
    },
    handleAgendar(item) {
      this.$router.push({
        // name: 'Agendamentos',
        name: 'NovoAgendamento',
        params: {
          pedidoSelecionado: item,
          tipoAgendamento: TipoAgendamento.DoPedido,
        },
      })
    },

    async handleEditar(item) {
      const pedido = await this.getPedido(item.public_id)
      pedido.quantidade_usada = pedido.quantidade - pedido.quantidade_restante
      this.$emit('editar', pedido)
    },

    async handleDistribuirParaTransportadora(item) {
      const pedido = await this.getPedido(item.public_id)
      pedido.replicacoes = pedido.pedidos.map((item) => {
        return {
          public_id: item.public_id,
          cadencia_livre: item.cadencia_livre,
          numero_pedido: item.numero_pedido,
          quantidade: item.quantidade,
          terminal: item.terminal.public_id,
          transportadora: item.transportadora.public_id,
          to: item.transportadora.public_id,
          suspendido: item.suspendido == true,
          suspensoes: item.suspensoes,
        }
      })
      this.$emit('distribuirParaTransportadora', pedido)
    },
    handleRelatorio(item) {
      item.quantidade_usada = item.quantidade - item.quantidade_restante
      this.$emit('showRelatorio', item)
    },
  },
}
</script>
